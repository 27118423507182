/* eslint-disable @typescript-eslint/no-shadow */

/* eslint-disable @typescript-eslint/no-unused-vars */
import { IProfileEntity } from 'apps/hospital-admin/src/app/interfaces/api/profile-entity';

import { IProfile } from '@locumsnest/components/src/lib/interfaces/profile';

import { IAttributeEntity } from './../../../../../apps/hospital-admin/src/app/interfaces/api/attribute';

export interface IApplicationDetails {
  listingId: number;
  applicationCount: number;
  currentApplicationStatus: ApplicationStatusEnum;
  profile: IProfile;
  title: string;
}

const APPLICATION_RECEIVED: IApplicationStatus = {
  code: 'APPLICATION_RECEIVED',
  display: 'Application Received',
  val: 0,
};

const SHORT_LISTED: IApplicationStatus = {
  code: 'SHORT_LISTED',
  display: 'Short Listed',
  val: 1,
};

const APPROVED: IApplicationStatus = {
  code: 'APPROVED',
  display: 'Approved',
  val: 2,
};

const DECLINED: IApplicationStatus = {
  code: 'DECLINED',
  display: 'Declined',
  val: 3,
};

const EXPIRED: IApplicationStatus = {
  code: 'EXPIRED',
  display: 'Expired',
  val: 4,
};

const CANCELLED: IApplicationStatus = {
  code: 'CANCELLED',
  display: 'Cancelled',
  val: 5,
};

const WITHDRAWN: IApplicationStatus = {
  code: 'WITHDRAWN',
  display: 'Withdrawn',
  val: 6,
};

const COMPLETED: IApplicationStatus = {
  code: 'COMPLETED',
  display: 'Completed',
  val: 7,
};

export enum ApplicationStatusEnum {
  APPLICATION_RECEIVED,
  SHORT_LISTED,
  APPROVED,
  CANCELLED,
  DECLINED,
  EXPIRED,
  WITHDRAWN,
  COMPLETED,
}

export interface IApplicationStatus {
  code: string;
  display: string;
  val: number;
}

export interface IApplicationBaseEntity {
  readonly declineReason: number;
  readonly declineReasonOther: string;
}

export interface IApplicationEntity<
  U extends IAttributeEntity | number = number,
  P extends IProfileEntity | string = string
> extends IApplicationBaseEntity {
  readonly id: number;
  readonly createdAt: Date;
  readonly bookingStatus: U;
  readonly applicationAcceptedOn: Date;
  readonly hiddenByUser: string;
  readonly cancelationDescription: string;
  readonly adhoc: boolean;
  readonly cancelledDate: Date;
  readonly listing: number;
  readonly profile: P;
  readonly lastOfficer: number;
  readonly cancellationSeen: boolean;
  readonly grade: number;
}

export interface IAlertDetailsList {
  totalCount: number;
  latestCancellationDate: Date;
  alertDetails: IAlertDetails[];
}

export interface IAlertDetails {
  id: number;
  listing?: number;
  alertDate: Date;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  title?: string;
  description?: string;
  applicant: string;
  profile: string;
  isRead: boolean;
  status: string;
  type: AlertTypeEnum;
}

export enum AlertTypeEnum {
  APPLICATION,
  TIMESHEET,
  MESSAGE,
  PASSPORT,
}

export interface IAlertSearchFilterFormState {
  readonly searchKeyword: string;
  readonly sortedField: string;
}
